
.bar {
  display: block;
  height: 3px;
  width: 30px;
  background-color: #338bbf;
  margin: 10px auto;
  cursor:pointer;
}

.middle { margin: 0 auto; }

.bar {
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.button {
  position: absolute;
  right: 15px;
  cursor: pointer;
  display: inline-block;
  width: auto;
  margin: 0 15px;
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.button.active .top {
  -webkit-transform: translateY(13px) rotateZ(45deg);
  -moz-transform: translateY(13px) rotateZ(45deg);
  -ms-transform: translateY(13px) rotateZ(45deg);
  -o-transform: translateY(13px) rotateZ(45deg);
  transform: translateY(13px) rotateZ(45deg);
}

.button.active .bottom {
  -webkit-transform: translateY(-13px) rotateZ(-45deg);
  -moz-transform: translateY(-13px) rotateZ(-45deg);
  -ms-transform: translateY(-13px) rotateZ(-45deg);
  -o-transform: translateY(-13px) rotateZ(-45deg);
  transform: translateY(-13px) rotateZ(-45deg);
}

.button.active .middle { width: 0; }